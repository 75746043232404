
































import Btn from '../../Misc/Btn/Btn.vue';
import Modal from '../Modal/Modal.vue';

export default {
    name: 'GeoModal',
    components: {Modal, Btn},
    props: ['value'],
    computed: {
        showModal: {
            get()
            {
                return this.value;
            },
            set(val)
            {
                this.$emit('input', val);
            }
        }
    },
    data()
    {
        return {
            location: [],
            gettingLocation: false,
            errorStr: ''
        };
    },
    methods: {
        async getLocation()
        {
            
            return new Promise((resolve, reject) => {
                
                if (!(
                    'geolocation' in navigator
                )) {
                    reject(new Error(this.$t('geoLocation.notAvailable')));
                }
                
                navigator.geolocation.getCurrentPosition(pos => {
                    resolve(pos);
                    this.showModal = false;
                }, err => {
                    reject(err);
                });
                
            });
        },
        async locatePosition()
        {
            this.gettingLocation = true;
            try {
                this.gettingLocation = false;
                this.location = await this.getLocation();
            } catch (e) {
                this.gettingLocation = false;
                this.errorStr = e.message;
            }
            
        }
    },
    mounted()
    {
        this.$watch(() => this.location, () => this.$emit('location', this.location.coords));
    }
};
