var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    _vm._b(
      {
        staticClass: "geoModal",
        attrs: { "is-small": "", "is-vertically-centered": "" },
        model: {
          value: _vm.showModal,
          callback: function ($$v) {
            _vm.showModal = $$v
          },
          expression: "showModal",
        },
      },
      "modal",
      Object.assign({}, _vm.$props, _vm.$attrs),
      false
    ),
    [
      _c("span", { staticClass: "geoModal__headline" }, [
        _vm._v(_vm._s(_vm.$t("branch.modal.locateMyPosition"))),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "geoModal__text" }, [
        _vm._v(_vm._s(_vm.$t("branch.modal.locateText"))),
      ]),
      _vm._v(" "),
      !_vm.gettingLocation
        ? _c(
            "btn",
            {
              staticClass: "geoModal__btn",
              nativeOn: {
                click: function ($event) {
                  return _vm.locatePosition.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("branch.modal.locateMyPosition")) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.gettingLocation
        ? _c("span", { staticClass: "geoModal__text geoModal__text-loading" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errorStr,
              expression: "errorStr",
            },
          ],
          staticClass: "geoModal__text geoModal__text-error",
        },
        [_vm._v(_vm._s(_vm.errorStr))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }