var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchBar" },
    [
      _c("form", { staticClass: "searchBar__form" }, [
        _c(
          "div",
          { staticClass: "searchBar__search" },
          [
            _c("label", { attrs: { for: "branch-search-query" } }, [
              _vm._v(_vm._s(_vm.$t("branchSearch.form.search.label"))),
            ]),
            _vm._v(" "),
            _c(
              "SelectBox",
              _vm._b(
                {
                  attrs: {
                    id: "branch-search-query",
                    placeholder: _vm.$t("branchSearch.form.search.label"),
                    items: _vm.suggestResult,
                    itemText: "address",
                    filter: _vm.itemFilter,
                    "item-value": null,
                    "show-spinner": _vm.showSpinner,
                    clearable: "",
                  },
                  on: { search: _vm.searchAddress },
                  scopedSlots: _vm._u([
                    {
                      key: "no-data",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.tooShort
                                  ? _vm.$t("branchSearch.form.search.tooShort")
                                  : _vm.$t("branchSearch.form.search.noResults")
                              ) +
                              "\n                "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.locationProxy,
                    callback: function ($$v) {
                      _vm.locationProxy = $$v
                    },
                    expression: "locationProxy",
                  },
                },
                "SelectBox",
                _vm.iconHoverContainerProps(),
                false
              ),
              [
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "input-start" },
                  [
                    _c(
                      "Icon",
                      _vm._b(
                        {
                          staticClass: "searchBar__icon",
                          attrs: { icon: "search" },
                        },
                        "Icon",
                        _vm.iconHoverProps(),
                        false
                      )
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "template",
                  { slot: "input-end" },
                  [
                    _c("icon", {
                      staticClass: "searchBar__icon searchBar__icon--geo",
                      attrs: { icon: "geo" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showModal = true
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "searchBar__radius" },
          [
            _c("label", { attrs: { for: "branch-search-radius" } }, [
              _vm._v(_vm._s(_vm.$t("branchSearch.form.radius.label"))),
            ]),
            _vm._v(" "),
            _c("select-box", {
              attrs: {
                id: "branch-search-radius",
                items: _vm.radiusOptions,
                "item-text": "label",
                "item-value": "value",
              },
              on: { select: _vm.emitRadius },
              model: {
                value: _vm.radiusProxy,
                callback: function ($$v) {
                  _vm.radiusProxy = $$v
                },
                expression: "radiusProxy",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("button", { attrs: { type: "submit" } }, [
          _vm._v(_vm._s(_vm.$t("branchSearch.form.search.submit"))),
        ]),
      ]),
      _vm._v(" "),
      _c("GeoModal", {
        on: { location: _vm.onGeoModalLocation },
        model: {
          value: _vm.showModal,
          callback: function ($$v) {
            _vm.showModal = $$v
          },
          expression: "showModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }