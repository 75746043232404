













































































import {debouncePromise, forEach, getPath, inflectToArray, inflectToSlug, isString, listIntersect, map} from '@labor-digital/helferlein';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import SelectBox from '../../../../../../Component/Form/SelectBox/SelectBox.vue';
import Icon from '../../../../../../Component/Misc/Icon/Icon.vue';
import IconHoverMixin from '../../../../../../Component/Misc/Icon/IconHoverMixin';
import GeoModal from '../../../../../../Component/Modal/GeoModal/GeoModal.vue';
import {Branch} from '../../../../../../Interface/Branch';
import {GeoCodeLocation} from '../../../../../../Interface/GeoCodeLocation';
import {BranchAndGeoLocationService} from '../../../../../../Service/BranchAndGeoLocationService';
import {FbPixelService} from '../../../../../../Service/FbPixelService';

export default {
    name: 'BranchSearch_SearchBar',
    components: {Icon, GeoModal, SelectBox},
    props: {
        context: null as ContentElementContext,
        location: {
            type: Object
        },

        radius: {
            type: Number
        }
    },
    mixins: [IconHoverMixin],
    data()
    {
        // Prepare the radius options
        const radiusOptions = [];
        const radiusPrefix = this.$t('branchSearch.form.radius.option.prefix');
        forEach([5, 15, 25, 50, 100, 250], v => {
            radiusOptions.push({
                label: radiusPrefix + ' ' + v + ' km',
                value: v
            });
        });

        return {
            showSpinner: false,

            suggestResult: this.location === null ? [] : [this.location],
            radiusOptions,

            tooShort: true,
            showModal: false
        };
    },
    computed: {
        locationProxy: {
            get()
            {
                return this.location;
            },
            set(v): void
            {
                this.$emit('location', v);
            }
        },
        radiusProxy: {
            get()
            {
                return this.radius;
            },
            set(v): void
            {
                this.$emit('radius', v);
            }

        }
    },
    methods: {
        itemFilter(item, queryText, itemText): boolean
        {
            return listIntersect(
                inflectToArray(inflectToSlug(queryText)),
                inflectToArray(inflectToSlug(itemText))
            ).length > 0;
        },

        onGeoModalLocation(query: { latitude: number, longitude: number })
        {
            // Update the collected location
            this.showSpinner = true;
            return this.context.appContext.axios.get('/location/reverse/' + query.latitude + '/' + query.longitude)
                       .then(response => {
                               this.showSpinner = false;
                               const address = getPath(response.data, '0.results.0.formatted_address', null);

                               if (!isString(address)) {
                                   alert('NT: Could not find a valid address for your Location, sorry!');
                                   return;
                               }

                               const location: GeoCodeLocation = {
                                   address: address.replace(/(,[^,].*?)$/, ''),
                                   latitude: query.latitude,
                                   longitude: query.longitude
                               };

                               this.suggestResult = [location];
                               this.locationProxy = location;
                               BranchAndGeoLocationService.setCurrentLocation(location);
                           }
                       );
        },

        searchAddress(query: string)
        {
            this.suggestResult = [];

            this.tooShort = query.length < 3;
            if (this.tooShort) {
                return;
            }

            this.showSpinner = true;
            debouncePromise('branchSearch', () => {
                return this.context.appContext.axios.get('/location/geocode/' + query);
            }, 300, true)
                .then(response => {
                        this.showSpinner = false;

                        this.suggestResult = map(response.data, (v: GeoCodeLocation) => {
                            v.address = v.address.replace(/(,[^,].*?)$/, '');
                            return v;
                        });
                    }
                );
        },

        emitLocation(item: Branch)
        {
            this.$emit('selected', item);
            FbPixelService.emit('FindLocation');
        },

        emitRadius()
        {
            this.$emit('radius', this.radius);
        }
    },

    watch: {
        location(n)
        {
            if (n === null) {
                this.suggestResult = [];
                return;
            }

            if (this.suggestResult.indexOf(n) === -1) {
                this.suggestResult = [n];
            }
        }
    }
};
